$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 50) {
        $(".nav-golf").addClass("dark-header");
        $("#back_to_top").addClass("on");
    } else {
        $(".nav-golf").removeClass("dark-header");
        $("#back_to_top").removeClass("on");
    }
});

$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 250) {
        $("#back_to_top").addClass("on");
    } else {
        $("#back_to_top").removeClass("on");
    }
});

function topFunction() {
   document.body.scrollTop = 0; // For Safari
   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
 }

document.addEventListener(
    "DOMContentLoaded", () => {
       let navbarsContent = [
         "<a href='https://www.facebook.com/aep.cci'><i class='fab fa-facebook-square'></i></a>",
         "<a href='https://www.instagram.com/ae_portugal/'><i class='fab fa-instagram'></i></a>",
         "<a href='https://www.linkedin.com/company/aeportugal'><i class='fab fa-linkedin-in'></a>",
         "<a href='https://www.youtube.com/channel/UCtFqb8yiBTpfKnnmEU2iSTA'><i class='fab fa-youtube'></a>"
       ];
       if (document.body.classList.contains('layout-golf')) {
          navbarsContent = [];
          document.querySelector('.minimenugolf').querySelectorAll('.mmenu-navbar-bottom').forEach(item => {
             navbarsContent.push(item.innerHTML);
          });
       }
       new Mmenu( "#mmenu", {
          wrappers: ["bootstrap"],
          "extensions": [
             "position-right"
          ],
          "navbars": [
             {
                "position": "bottom",
                "content": navbarsContent
             }
          ]
       });
    }
 );