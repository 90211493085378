require('bootstrap/dist/css/bootstrap.css');
require('bootstrap');
require('bootstrap-select/dist/css/bootstrap-select.css');
require('bootstrap-select');
require('jquery-validation');
require('./css/core.css');
require('./js/core.js');
require('./plugins/cookie/cookie.css');
require('./plugins/cookie/cookie.js');
require('./custom.js');
