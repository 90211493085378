/* COOKIE BAR */

import { data } from "jquery";

    //COOKIES ======================================
    $("body").on("click","#cookies-modal .modalclose",function(e){
        $( "#cookies-modal" ).fadeOut( "slow", function() {
            $("#cookies-modal").removeClass('d-block');
        });
        return false;
    });
    
    $("body").on("click","#cookies-modal .accept",function(e){
        e.preventDefault();
        var modal = $("#cookies-modal");
        if (!modal.find('.accept').hasClass("loading"))
        {
            $.ajax({
                method: "POST",
                url: modal.data("accept"),
                data: modal.data(),
                beforeSend: function(){
                    modal.find('.accept').addClass("loading");
                },
                success: function (response) {
                    $( "#cookies-modal" ).fadeOut( "slow", function() {
                        $("#cookies-modal").removeClass('d-block');
                    });
                },
                error: function(jqXHR,textStatus,errorThrown){
                    console.log("Error accepting cookies - " + textStatus + ":" + errorThrown);
                },
                complete: function()
                {
                    modal.find('.accept').removeClass("loading");
                }
            })
        }
    });


    // Show modal cookies if available
    if ($("#cookies-modal").length){
        $("#cookies-modal").addClass('d-block');
        var dataurl=$("#cookies-modal").data("url");
        if(dataurl!=""){
            //                  loadContent(url,scroll,target,block,skin,extradata,callback,pageskin,blockskin)
            $("#cookies-modal").loadContent(dataurl,false,"","","direct=1",null,"","cookie_bar");
        }
    }

