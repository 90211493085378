/* ADMINBAR */
$("body").on("click", ".studio-logo-fixed", function () {
    $(".adminbar").toggleClass("open");
});

/* ANIMATIONS ON SCROLL */
import AOS from "aos";
import 'aos/dist/aos.css';
AOS.init({once: true});

/* LOADCONTENT */
$.fn.loadContent = function (url, scroll, block, skin, extradata, callback, pageskin, blockskin) {
    var target = this;
    if (scroll) {
        $("html,body").animate({ scrollTop: target.offset().top - 150 }, 400).promise().then(function () {
            //if(!cancel){
            target.html('<div class="d-flex justify-content-center my-3 preloader"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>').fadeIn("slow");
            //}
        });
    } else {
        // if(!cancel){
        target.html('<div class="d-flex justify-content-center my-3 preloader"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>').fadeIn("slow");
        //}
    }
    var datatosend = "pageskin=" + pageskin + "&skin=" + skin + "&block=" + block + "&" + extradata + "&blockskin=" + blockskin;
    $.ajax({
        url: url,
        type: "POST",
        data: datatosend,
        success: function (html) {
            //cancel=true;
            target.html(html);
            target.trigger("change");
            if (callback != void (0)) {
                callback(url);
            }
        }
    });
    return this;
}

  /*Menu Mobile
  ========================================================*/
  $('#menuToggle').on('click', function () {
    $(this).toggleClass('open');
    $('body').toggleClass('push-toright');
    $('.menu-mobile').toggleClass('open');
  });
  window.onresize = function () {
    if (window.innerWidth >= 992) {
      $('#menuToggle').removeClass('open');
      $('body').removeClass('push-toright');
      $('.menu-mobile').removeClass('open');
    }
  }

    /* FILTERS
  ========================================================*/
  $('.filter-toggle').on('click', function () {
    $(this).prev('.filter-form').toggleClass('open').slideToggle();
  }); 
