// Optional
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

// require('bootstrap-autocomplete');
require('./vendor/select2/select2.min.css');
require('./vendor/select2/select2.min.js');
$.fn.select2.amd.define('select2/i18n/pt', [], require("./vendor/select2/i18n/pt"));



require('./css/custom/main.css');
require('./css/custom/main2.css');
// import 'animate.css'

require('./css/custom/ckeditor.css');
require('./css/custom/custom.css');
require('./css/custom/custom_formacao.css');
require('./css/custom/custom_formacao_tiago.css');
require('./js/custom/custom.js');
require('./js/custom/custom_formacao.js');
require('./js/custom/custom_tiago.js');
